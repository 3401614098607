import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";

export default function Card({ title, path, imgData = null, name = "" }) {
  return (
    <Box
      as={GatsbyLink}
      to={path}
      maxW={"350px"}
      m={6}
      shadow={"lg"}
      roundedBottom={"md"}
    >
      <Box
        sx={{
          overflow: "hidden",
          "& > *": {
            objectFit: "cover",
            height: "200px",
            width: "100%",
          },
        }}
        roundedTop={"md"}
      >
        <GatsbyImage image={imgData} alt={name} />
      </Box>
      <Box m={3} textAlign={"center"}>
        <Heading textTransform="uppercase" isTruncated>
          {title}
        </Heading>
        <Text size="sm" textTransform={"capitalize"}>
          {name}
        </Text>
      </Box>
    </Box>
  );
}
